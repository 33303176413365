import { useEffect } from "react";
import { isSafari } from "react-device-detect";
import ReactPlayer from "react-player";
import styles from "./Carousel.module.scss";

type Props = {
  url: string;
  isPlaying?: boolean;
  onBuffer?: () => void;
  onBufferEnd?: () => void;
  onEnded?: () => void;
  onError?: (...args: unknown[]) => void;
  showControls?: boolean;
  playerRef: React.RefObject<ReactPlayer | null>;
};

const Player: React.FC<Props> = ({
  url,
  isPlaying = false,
  onBuffer,
  onBufferEnd,
  onEnded,
  onError,
  playerRef,
  showControls = false,
}) => {
  useEffect(() => {
    const player = playerRef.current;
    if (player && isPlaying) {
      const hlsPlayer = player.getInternalPlayer("hls");
      hlsPlayer?.startLoad(-1);
    }
  }, [isPlaying, playerRef]);

  const handleReady = (player: ReactPlayer) => {
    if (isPlaying) {
      player.getInternalPlayer("hls")?.startLoad(-1);
    }
  };

  return (
    <ReactPlayer
      ref={playerRef}
      url={url}
      playing={isPlaying}
      className={styles["react-player"]}
      playsinline
      onBuffer={onBuffer}
      onBufferEnd={onBufferEnd}
      onEnded={onEnded}
      onError={onError}
      controls={showControls}
      onReady={handleReady}
      width="100%"
      height="100%"
      config={{
        file: {
          forceDisableHls: isSafari,
          hlsOptions: {
            autoStartLoad: isPlaying,
          },
        },
      }}
    />
  );
};

export default Player;
